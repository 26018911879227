/**
*
*   Shopify Store
*       - Loads the Store Data
*
**/
const obj = {
    load: {
        type: 'query',
        variables: '$handle: String!',
        request: `
            pageByHandle(handle: $handle){
                body
                bodySummary
                createdAt
                id
                title
                trackingParameters
                seo {
                    title
                    description
                }
            }
        `
    }
}

//Export
export default obj;