/**
*
*   Artiles
*       - GraphQL for Shopify Articles
*
**/
const article = `
    authorV2 {
        bio
        email
        firstName
        lastName
        name
    }
    content
    contentHtml
    excerpt
    excerptHtml
    handle
    id
    blog {
        id
        handle
        title
    }
    image {
        id
        altText
        height
        width
        url
    }
    video: metafield(
        namespace: "feature"
        key: "video"
    ){
        value
    }
    json: metafield(
        namespace: "tools"
        key: "articlebuilder"
    ){
        value
    }
    onlineStoreUrl
    publishedAt
    tags
    title
`;

const obj =  {



    //Load all the articles
    get: {
        type: 'query',
        variables: '$handle: String!',
        request: `
            blog(handle: $handle){
                id
                title
                onlineStoreUrl
                handle
                articles(first:250){
                    edges {
                        node {
                            tags
                        }
                    }
                }
                seo {
                    title
                    description
                }
            }
        `
    },


    //Load the Store Data
    article: {
        type: 'query',
        variables: '$blog: String!, $article: String!',
        request: `
            blog(handle: $blog){
                id
                title
                onlineStoreUrl
                handle
                articleByHandle(handle: $article){
                    ${article}
                }
                seo {
                    title
                    description
                }
            }
        `
    },



    //Load all the articles
    articles: {
        type: 'query',
        variables: '$handle: String!',
        request: `
            blog(handle: $handle){
                articles(first:250, reverse: true){
                    edges {
                        node {
                            ${article}
                        }
                    }
                }
            }
        `
    },




    /**
    *
    *   format
    *       - Format the Collection List
    *
    *   Params:
    *       product     (Object) The Product Objects
    *
    **/
    format: (obj,response) => {

        //Update the Articles
        if( response.articles )
            response.articles = response.articles.edges.map( article => article.node );

        //Prepare the Article if loaded by handle
        if( response.articleByHandle ){
            response.article = {
                ...response.articleByHandle,
                video: ( response.articleByHandle.video?.value ? response.articleByHandle.video.value : null ),
                json: ( response.articleByHandle.json?.value ? obj.parseJSON( response.articleByHandle.json.value ) : null )
            };
            delete response.articleByHandle;
        }

        //Return Formatted Results
        return response;
    }
}

//Export
export default obj;