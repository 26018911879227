/**
*
*   Articles
*       - GraphQL for Shopify Articles
*
**/
const article = `
    authorV2 {
        bio
        email
        firstName
        lastName
        name
    }
    content
    contentHtml
    excerpt
    excerptHtml
    handle
    id
    blog {
        id
        handle
        title
    }
    image {
        id
        altText
        height
        width
        url
    }
    video: metafield(
        namespace: "feature"
        key: "video"
    ){
        value
    }
    onlineStoreUrl
    publishedAt
    tags
    title
`;

const obj =  {



    //Load all the articles
    latest: {
        type: 'query',
        variables: '$first: Int!',
        request: `
            articles(first:$first, reverse:true){
                edges {
                    node {
                        ${article}
                    }
                }
            }
        `
    },



    /**
    *
    *   format
    *       - Format the Collection List
    *
    *   Params:
    *       product     (Object) The Product Objects
    *
    **/
    format: (obj,response) => {

        //Prep the
        response = response.edges.map( item => ({
            ...item.node,
            video: ( item.node.video?.value ? item.node.video.value : null )
        }));

        //Return Formatted Results
        return response;
    }
}

//Export
export default obj;