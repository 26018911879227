/**
*
*   Shopify Store
*       - Loads the Store Data
*
**/


//The Default Product Query Data
const query = `
    id
    title
    description
    handle
    tags
    vendor
    productType
    availableForSale

    featuredImage {
        url(transform:{ maxWidth: 600, maxHeight:600 })
    }


    media(first: 10) {
        edges {
            node {
                id
                mediaContentType
                presentation {
                    asJson(format: IMAGE)
                }
                previewImage {
                    altText
                    height
                    url(transform:{ maxWidth: 1200, maxHeight:1200 })
                    width
                }
                ... on Video {
                    sources {
                        format
                        height
                        mimeType
                        url
                        width
                    }
                }
                ... on ExternalVideo {
                    id
                    host
                    originUrl
                }
                ... on MediaImage {
                    image {
                        url(transform:{ maxWidth: 1200, maxHeight:1200 })
                    }
                }
            }
        }
    }

    options {
        name
        values
    }

    collections(first: 10) {
        edges {
            node {
                id
            }
        }
    }

    variants(first: 5) {
        edges {
            node {
                id,
                title
                availableForSale
                weight
                weightUnit

                price {
                    amount
                    currencyCode
                }

                compareAtPrice {
                    amount
                    currencyCode
                }

                selectedOptions {
                    name
                    value
                }

                image {
                    url(transform:{ maxWidth: 600, maxHeight:600 })
                }

                soldout: metafield(
                    namespace: "accentuate"
                    key: "soldout"
                ){
                    value
                }

                preorder: metafield(
                    namespace: "accentuate"
                    key: "preorder"
                ){
                    value
                }

                wholesale: metafield(
                    namespace: "accentuate"
                    key: "wholesale"
                ){
                    value
                }

            }
        }
    }

    scientificName: metafield(
        namespace: "scientific"
        key: "name"
    ){
        value
    }

    lifestyle: metafield(
        namespace: "accentuate"
        key: "lifestyle"
    ){
        value
    }

    soldout: metafield(
        namespace: "accentuate"
        key: "soldout"
    ){
        value
    }

    preorder: metafield(
        namespace: "accentuate"
        key: "preorder"
    ){
        value
    }

    profile: metafield(
        namespace: "accentuate"
        key: "profile"
    ){
        value
    }

    benefits: metafield(
        namespace: "accentuate"
        key: "benefits"
    ){
        value
    }

    caption: metafield(
        namespace: "accentuate"
        key: "caption"
    ){
        value
    }

    origin: metafield(
        namespace: "accentuate"
        key: "origin"
    ){
        value
    }

    extraction: metafield(
        namespace: "accentuate"
        key: "extraction"
    ){
        value
    }

    badgeText: metafield(
        namespace: "badge"
        key: "text"
    ){
        value
    }

    badgeBackgroundColor: metafield(
        namespace: "badge"
        key: "background-color"
    ){
        value
    }

    badgeTextColor: metafield(
        namespace: "badge"
        key: "text-color"
    ){
        value
    }

    filtersScents: metafield(
        namespace: "filters"
        key: "scents"
    ){
        value
    }

    filtersApplications: metafield(
        namespace: "filters"
        key: "applications"
    ){
        value
    }

    filtersTreatments: metafield(
        namespace: "filters"
        key: "treatments"
    ){
        value
    }

    filtersBenefits: metafield(
        namespace: "filters"
        key: "benefits"
    ){
        value
    }

    boxMedia: metafield(
        namespace: "box"
        key: "media"
    ){
        value
    }

    boxHeadline: metafield(
        namespace: "box"
        key: "headline"
    ){
        value
    }
`;



//Export Functions
const obj = {

    //Load all the products
    all: {
        type: 'query',
        variables: null,
        request: `
            products(first:200) {
                edges {
                    node {
                        ${query}
                    }
                }
            }
        `
    },

    //Load all the products
    latest: {
        type: 'query',
        variables: null,
        request: `
            products(first:10,reverse:true,sortKey:CREATED_AT,query:"tag_not:Hidden") {
                edges {
                    node {
                        ${query}
                    }
                }
            }
        `
    },

    //Load all the products
    bestsellers: {
        type: 'query',
        variables: null,
        request: `
            products(first:10,sortKey:BEST_SELLING,query:"tag_not:Hidden") {
                edges {
                    node {
                        ${query}
                    }
                }
            }
        `
    },

    //Load the Store Data
    get: {
        type: 'query',
        variables: '$ids: [ID!]!',
        request: `
            nodes(ids:$ids){
                ... on Product {
                    ${query}
                }
            }
        `
    },



    //Get Product Recommendations
    recommendations: {
        type: 'query',
        variables: '$id: ID!, $intent: ProductRecommendationIntent!',
        request: `
            productRecommendations(productId:$id, intent: $intent){
                ${query}
            }
        `
    },




    /**
    *
    *   format
    *       - Format the Product List
    *
    *   Params:
    *       product     (Object) The Product Objects
    *
    **/
    format: (obj,response) => {

        //The Response
        const output = [];

        //Loop the response
        response.forEach( (product,i) => {

            if( !product ) return;

            //Is the product sold out?
            const soldout = ( product.soldout?.value ? Boolean( product.soldout.value ) : false );

            //Setup Product Data
            output[i] = {
                ...response[i],
                ...{

                    //Featured Image
                    featuredImage: ( product.featuredImage?.url || { url: null } ),

                    //INCI Name
                    inci:  ( product.scientificName?.value ? product.scientificName?.value : null ),

                    //Profile
                    profile: ( product.profile?.value ? obj.parseJSON( product.profile.value ) : null ),

                    //Benefits
                    benefits: ( product.benefits?.value ? obj.parseJSON( product.benefits.value ) : null ),

                    //Origin
                    origin: ( product.origin?.value ? product.origin.value : null ),

                    //Caption
                    caption: ( product.caption?.value || null ),

                    //Sold Out
                    soldout: soldout,

                    //Profile
                    preorder: ( product.preorder?.value ? Boolean( product.soldout.preorder ) : false ),

                    //Extraction
                    extraction: ( product.extraction?.value ? product.extraction.value : null ),

                    //Lifestyle
                    lifestyle: ( product.lifestyle?.value ? obj.parseJSON( product.lifestyle.value )[0] : null ),

                    //Box Photo
                    box: {
                        headline:   ( product.boxHeadline?.value ? obj.parseJSON( product.boxHeadline.value ) : null ),
                        photo:      ( product.boxMedia?.value ? obj.parseJSON( product.boxMedia.value )[0] : null ),
                    },

                    //Art Photo
                    art: {
                        photo:      ( product.boxMedia?.value ? obj.parseJSON( product.boxMedia.value )[0] : null ),
                        headline:   ( product.boxHeadline?.value ? obj.parseJSON( product.boxHeadline.value ) : null )
                    },

                    //Filters
                    filter: {
                        scents:         ( product.filtersScents?.value?.split('|') || null ),
                        applications:   ( product.filtersApplications?.value?.split('|') || null ),
                        treatments:     ( product.filtersTreatments?.value?.split('|') || null ),
                        benefits:       ( product.filtersBenefits?.value?.split('|') || null )
                    },

                    //Badges
                    badges: ((badges)=>{

                        //Parse the JSON
                        ['badgeText','badgeBackgroundColor','badgeTextColor'].forEach(
                            type => {
                                if(product[type]) product[type] = obj.parseJSON( product[type].value )
                            }
                        );

                        //Loop through the badge text
                        if( product.badgeText ){
                            for( var i=0; i < product.badgeText.length; i++ ){
                                badges.push({
                                    text:   product.badgeText[i],
                                    styles: {
                                        backgroundColor:    ( product.badgeBackgroundColor?.[i] || null ),
                                        color:              ( product.badgeTextColor?.[i] || null )
                                    }
                                });
                            }
                        }

                        return badges;
                    })([]),

                    //Media
                    media: ((media) => {
                        product?.media?.edges?.forEach( asset => {
                            media.push({
                                ...asset.node
                            })
                        });
                        return media;
                    })([]),

                    //Collections
                    collections: ((collections) => {
                        product.collections.edges.forEach( collection => {
                            collections.push({
                                ...collection.node
                            })
                        });
                        return collections;
                    })([]),

                    //Add the Variants + Free Gifts
                    ...((variants,samples) => {
                        product.variants.edges.forEach( variant => {

                            //Save price to bypass formatting
                            const amt = variant.node.price.amount;

                            //Format Pricing
                            variant.node.price.amount = variant.node.price.amount;

                            //Formate Discount
                            if( variant.node.compareAtPrice?.amount )
                                variant.node.compareAtPrice.amount = variant.node.compareAtPrice.amount;

                            //Store the Options
                            variant.node.options = variant.node.title.split(' / ');

                            //Set the Preorder
                            variant.node.preorder = ( variant.node.preorder?.value ? Boolean( variant.node.preorder.value ) : false );

                            //Set the Sold Out
                            variant.node.soldout = ( variant.node.soldout?.value ? Boolean( variant.node.soldout.value ) : false );

                            //Set the Sold Out
                            variant.node.soldout = ( variant.node.soldout || soldout || !variant.node.availableForSale );

                            //Is Available for Wholesale
                            variant.node.wholesale = ( variant.node.wholesale?.value ? Boolean( variant.node.wholesale.value ) : false );

                            //Overwrite
                            if( amt > 0 ) variants.push({
                                ...variant.node
                            });

                            if( amt == 0 ) samples.push({
                                ...variant.node
                            })

                        });
                        return {
                            variants:   variants,
                            samples:    samples
                        }
                    })([],[])

                }
            }

            //Get the Base Variant
            output[i].variant = ((variant)=>{
                output[i].variants.forEach( item => {
                    if( !item.soldout && ( parseFloat( item.price.amount ) < parseFloat( variant.price.amount ) ) ){
                        variant = item;
                    }
                });
                return variant;
            })( output[i].variants[0] );

            //Update the Soldout to include variants (If no inventory tracked, default to available)
            output[i].soldout = ( soldout || output[i].variant?.soldout );

            //Cleanup
            delete output[i].filtersScents;
            delete output[i].filtersApplications;
            delete output[i].filtersTreatments;
            delete output[i].filtersBenefits;
            delete output[i].badgeText;
            delete output[i].badgeBackgroundColor;
            delete output[i].badgeTextColor;
            delete output[i].boxHeadline;
            delete output[i].boxMedia;

        });

        //Alphabetize
        output.sort( (a,b) => a.title.localeCompare(b.title) );

        //Return
        return output;
    }

}

//Export
export default obj;