/**
*
*   Shopify Store
*       - Loads the Store Data
*
**/
const obj = {


    //Load the Store Data
    data: {
        type: 'query',
        variables: null,
        request: `
            shop {
                name
                description
                shipsToCountries
                brand {
                    coverImage {
                        image {
                           id
                           src
                           url
                        }
                    }
                    squareLogo {
                        mediaContentType
                        image {
                            src
                            url
                            width
                        }
                    }
                    shortDescription
                    slogan
                }
                paymentSettings {
                    countryCode
                    currencyCode
                }
                primaryDomain {
                    host
                    sslEnabled
                    url
                }
                privacyPolicy {
                    url
                }
                shippingPolicy {
                    url
                }
                refundPolicy {
                    url
                }
                subscriptionPolicy {
                    url
                }
                termsOfService {
                    url
                }
            }
            localization {
                language {
                    name
                    isoCode
                    endonymName
                }
                country {
                    currency {
                        name
                        symbol
                        isoCode
                    }
                    market {
                        handle
                        id
                    }
                    name
                    unitSystem
                }
                availableLanguages {
                    endonymName
                    isoCode
                    name
                }
                availableCountries {
                    isoCode
                    name
                    unitSystem
                    currency {
                        isoCode
                        name
                        symbol
                    }
                    availableLanguages {
                        endonymName
                        isoCode
                        name
                    }
                }
            }
        `
    }
}

//Export
export default obj;