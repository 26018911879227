/**
*
*   Shopify Store
*       - Loads the Store Data
*
**/
const obj = {


    //Load the Store Data
    load: {
        type: 'query',
        variables: '$handle: String!',
        request: `
            collection(handle: $handle){
                title
                handle
                id
                description
                image {
                    url
                }
                seo {
                    title
                    description
                }
                products(first: 100){
                    edges {
                        node {
                            id
                            handle
                            title
                            description
                            tags
                            media(first: 10) {
                                edges {
                                    node {
                                        id
                                        mediaContentType
                                        presentation {
                                            asJson(format: IMAGE)
                                        }
                                        previewImage {
                                            altText
                                            height
                                            url(transform:{ maxWidth: 1200, maxHeight:1200 })
                                            width
                                        }
                                        ... on Video {
                                            sources {
                                                format
                                                height
                                                mimeType
                                                url
                                                width
                                            }
                                        }
                                        ... on ExternalVideo {
                                            id
                                            host
                                            originUrl
                                        }
                                        ... on MediaImage {
                                            image {
                                                url(transform:{ maxWidth: 1200, maxHeight:1200 })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                filterName: metafield(
                    namespace: "filter"
                    key: "name"
                ){
                    value
                }

                filterOptions: metafield(
                    namespace: "filter"
                    key: "options"
                ){
                    value
                }

                sidebarParent: metafield(
                    namespace: "sidebar"
                    key: "parent"
                ){
                    value
                }

                sidebarLink: metafield(
                    namespace: "sidebar"
                    key: "links"
                ){
                    value
                }

                footerTitle: metafield(
                    namespace: "footer"
                    key: "title"
                ){
                    value
                }

                footerCollection: metafield(
                    namespace: "footer"
                    key: "collection"
                ){
                    value
                }

                footerImage: metafield(
                    namespace: "footer"
                    key: "image"
                ){
                    value
                }

                adzones: metafield(
                    namespace: "accentuate"
                    key: "adzones"
                ){
                    value
                }
            }
        `
    },

    //Load the Collection Title
    title: {
        type: 'query',
        variables: '$handle: String!',
        request: `
            collection(handle: $handle){
                title
                handle
            }
        `
    },



    /**
    *
    *   format
    *       - Format the Collection List
    *
    *   Params:
    *       product     (Object) The Product Objects
    *
    **/
    format: (obj,response) => {
        response = {
            ...response,
            ...{

                //Products
                products: ((products)=>{
                    response.products.edges.forEach(product=>{

                        //Skip hidden ones
                       /* if(
                            product.node.tags.map( tag => {
                                return tag.toLowerCase().indexOf('hidden') > -1
                            }).indexOf(true) > -1
                        ) return; */

                        //Product Media
                        product.node.media = ((media) => {
                            product.node.media.edges.forEach( asset => {
                                media.push({
                                    ...asset.node
                                })
                            });
                            return media;
                        })([]);

                        //
                        products.push({
                            ...product.node
                        });
                    });
                    return products.sort( (a,b) => a.title.localeCompare(b.title) );
                })([]),

                //Filters
                filters: ((filters)=>{

                    //Parse the JSON
                    ['filterName','filterOptions'].forEach(
                        type => {
                            if(response[type]) response[type] = obj.parseJSON( response[type].value )
                        }
                    );

                    //Loop through the badge text
                    if( response.filterName ){
                        response.filterName.forEach( (name, i) => {
                            if( response.filterOptions?.[i] ) filters.push({
                                name:       name,
                                options:    response.filterOptions[i].split('|')
                            });
                        })
                    }

                    //
                    return filters;

                })([]),

                //Sidebar
                sidebar: {
                    parent: ( response.sidebarParent ? obj.parseJSON( response.sidebarParent.value ) : null ),
                    links:  ( response.sidebarLink?.value.split('|') || null )
                },

                //Footer
                footer: ((footer)=>{

                    //Parse the JSON
                    ['footerTitle','footerCollection','footerImage'].forEach(
                        type => {
                            if(response[type]) response[type] = obj.parseJSON( response[type].value )
                        }
                    );

                    //Loop through the badge text
                    if( response.footerTitle ){
                        response.footerTitle.forEach( (title, i) => {
                            footer.push({
                                title:          title,
                                collection:     response.footerCollection[i],
                                image:          response.footerImage[i]
                            })
                        })
                    }

                    //
                    return footer;

                })([]),

                //Adzones
                adzones: ( response.adzones ? obj.parseJSON( response.adzones.value ) : null )

            }
        }

        //Cleanup
        delete response.filterName;
        delete response.filterOptions;
        delete response.sidebarParent;
        delete response.sidebarLink;
        delete response.footerTitle;
        delete response.footerCollection;
        delete response.footerImage;

        //Return Formatted Results
        return response;
    }
}

//Export
export default obj;