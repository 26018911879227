/**
*
*   Shopify Store
*       - Loads the Store Data
*
**/

//Address Field
const address = `
    address1
    address2
    city
    company
    country
    countryCodeV2
    firstName
    formatted
    formattedArea
    id
    lastName
    latitude
    longitude
    name
    phone
    province
    provinceCode
    zip
`;

const customer = `
    id
    acceptsMarketing
    createdAt
    displayName
    email
    firstName
    lastName
    numberOfOrders
    phone
    tags
    updatedAt
    defaultAddress {
        ${address}
    }
    photo: metafield(
        namespace: "profile"
        key: "photo"
    ){
        id,
        value
    }
    username: metafield(
        namespace: "profile"
        key: "username"
    ){
        id,
        value
    }
`


//Customer Functions
const obj = {


    //Load the Store Data
    login: {
        type: 'mutation',
        name: 'customerAccessTokenCreate',
        variables: '$input: CustomerAccessTokenCreateInput!',
        request: `
            customerAccessTokenCreate(input: $input) {
                customerUserErrors {
                    code
                    field
                    message
                }
                customerAccessToken {
                    accessToken
                    expiresAt
                }
            }
        `
    },




    //Create a Customer
    create: {
        type: 'mutation',
        name: 'customerCreate',
        variables: '$input: CustomerCreateInput!',
        request: `
            customerCreate(input: $input) {
                customerUserErrors {
                    code
                    field
                    message
                }
                customer {
                    id
                    firstName
                    lastName
                }
            }
        `
    },




    //Activate a Customer
    activate: {
        type: 'mutation',
        name: 'customerActivate',
        variables: '$id: ID!, $input: CustomerActivateInput!',
        request: `
            customerActivate(id: $id, input: $input) {
                customerUserErrors {
                    code
                    field
                    message
                }
                customer {
                    id
                }
            }
        `
    },




    //Send a Forgot Password email
    recover: {
        type: 'mutation',
        name: 'customerRecover',
        variables: '$email: String!',
        request: `
            customerRecover(email: $email) {
                customerUserErrors {
                    code
                    field
                    message
                }
            }
        `
    },




    //Reset the Password
    reset: {
        type: 'mutation',
        name: 'customerReset',
        variables: '$id: ID!, $input: CustomerResetInput!',
        request: `
            customerReset(id: $id, input: $input) {
                customerUserErrors {
                    code
                    field
                    message
                }
                customer {
                    id
                }
            }
        `
    },



    //Get the Customer
    get: {
        type: 'query',
        variables: '$token: String!',
        request: `
            customer(customerAccessToken: $token) {
                ${customer}
            }
        `
    },



    //Get the Customer
    update: {
        type: 'mutation',
        name: 'customerUpdate',
        variables: '$token: String!, $input: CustomerUpdateInput!',
        request: `
            customerUpdate(customerAccessToken: $token, customer: $input) {
                customerUserErrors {
                    code
                    field
                    message
                }
                customer {
                    ${customer}
                }
            }
        `
    },



    //Get the Customer
    addresses: {


        //Get the customer address list
        list: {
            type: 'query',
            variables: '$token: String!',
            request: `
                customer(customerAccessToken: $token) {
                    id
                    addresses(first: 50){
                        edges {
                            node {
                                ${address}
                            }
                        }
                    }
                }
            `
        },


        //Delete a customer addresss
        delete: {
            type: 'mutation',
            name: 'customerAddressDelete',
            variables: '$token: String!, $id: ID!',
            request: `
                customerAddressDelete(customerAccessToken: $token, id: $id) {
                    customerUserErrors {
                        code
                        field
                        message
                    }
                    deletedCustomerAddressId
                }
            `
        },


        //Create a new address for the customer
        create: {
            type: 'mutation',
            name: 'customerAddressCreate',
            variables: '$token: String!, $address: MailingAddressInput!',
            request: `
                customerAddressCreate(customerAccessToken: $token, address: $address) {
                    customerUserErrors {
                        code
                        field
                        message
                    }
                    customerAddress {
                        ${address}
                    }
                }
            `
        },


        //Update a Customer Address
        update: {
            type: 'mutation',
            name: 'customerAddressUpdate',
            variables: '$token: String!, $id: ID!, $address: MailingAddressInput!',
            request: `
                customerAddressUpdate(customerAccessToken: $token, id: $id, address: $address) {
                    customerUserErrors {
                        code
                        field
                        message
                    }
                    customerAddress {
                        ${address}
                    }
                }
            `
        },


        //Update a Customer Address
        setDefault: {
            type: 'mutation',
            name: 'customerDefaultAddressUpdate',
            variables: '$token: String!, $addressId: ID!',
            request: `
                customerDefaultAddressUpdate(customerAccessToken: $token, addressId: $addressId) {
                    customerUserErrors {
                        code
                        field
                        message
                    }
                    customer {
                        ${customer}
                    }
                }
            `
        },

    },



    //Get the Customer
    order: {

        //Get an Order Details
        get: {
            type: 'query',
            variables: '$query: String!, $token: String!',
            request: `
                customer(customerAccessToken: $token) {
                    orders(query:$query, first:1){
                        edges {
                            node {
                                id
                                name
                                orderNumber
                                processedAt
                                financialStatus
                                fulfillmentStatus
                                totalPrice {
                                    amount
                                    currencyCode
                                }
                            }
                        }
                    }
                }
            `
        },

        //Get a Customer Order History
        history: {
            type: 'query',
            variables: '$token: String!',
            request: `
                customer(customerAccessToken: $token) {
                    orders(first:100){
                        edges {
                            node {
                                id
                                name
                                orderNumber
                                processedAt
                                financialStatus
                                fulfillmentStatus
                                totalPrice {
                                    amount
                                    currencyCode
                                }
                            }
                        }
                    }
                }
            `
        },
    },

    /*

                addresses {
                    address1
                    address2
                    city
                    company
                    country
                    countryCodeV2
                    firstName
                    formatted {
                        withName
                        withCompany
                    }
                    formattedArea
                    id
                    lastName
                    latitude
                    longitude
                    name
                    phone
                    province
                    provinceCode
                    zip
                }
    */




    /**
    *
    *   format
    *       - Format the Collection List
    *
    *   Params:
    *       product     (Object) The Product Objects
    *
    **/
    format: (obj,customer) => {

        //Remove edges & nodes from order
        if( customer.orders )
            customer.orders = customer.orders.edges.map( order => order.node );

        //Remove edges & nodes from addresses
        if( customer.addresses )
            customer.addresses = customer.addresses.edges.map( address => address.node );

        //Return the customer
        return customer;
    }
}



//Export
export default obj;