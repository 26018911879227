/**
*
*   Shopify Store
*       - Loads the Store Data
*
**/
const obj = {

    //Load the Product Data
    load: {
        type: 'query',
        variables: '$handle: String!',
        request: `
            product(handle: $handle){
                id
                title
                description
                handle
                tags
                productType
                vendor
                availableForSale

                featuredImage {
                    url(transform:{ maxWidth: 1200, maxHeight:1200 })
                }

                media(first: 10) {
                    edges {
                        node {
                            id
                            mediaContentType
                            presentation {
                                asJson(format: IMAGE)
                            }
                            previewImage {
                                altText
                                height
                                url(transform:{ maxWidth: 1200, maxHeight:1200 })
                                width
                            }
                            ... on Video {
                                sources {
                                    format
                                    height
                                    mimeType
                                    url
                                    width
                                }
                            }
                            ... on ExternalVideo {
                                id
                                host
                                originUrl
                            }
                            ... on MediaImage {
                                image {
                                    url(transform:{ maxWidth: 1200, maxHeight:1200 })
                                }
                            }
                        }
                    }
                }

                options {
                    name
                    values
                }

                collections(first: 10) {
                    edges {
                        node {
                            id
                            handle
                            title
                        }
                    }
                }

                variants(first: 5) {
                    edges {
                        node {
                            id
                            title
                            availableForSale
                            sku

                            price {
                                amount
                                currencyCode
                            }

                            compareAtPrice {
                                amount
                                currencyCode
                            }

                            selectedOptions {
                                name
                                value
                            }

                            image {
                                url(transform:{ maxWidth: 600, maxHeight:600 })
                            }

                            soldout: metafield(
                                namespace: "accentuate"
                                key: "soldout"
                            ){
                                value
                            }

                            preorder: metafield(
                                namespace: "accentuate"
                                key: "preorder"
                            ){
                                value
                            }

                            wholesale: metafield(
                                namespace: "accentuate"
                                key: "wholesale"
                            ){
                                value
                            }

                        }
                    }
                }

                template: metafield(
                    namespace: "accentuate"
                    key: "template"
                ){
                    value
                }

                scientificName: metafield(
                    namespace: "scientific"
                    key: "name"
                ){
                    value
                }

                madeLocal: metafield(
                    namespace: "accentuate"
                    key: "local"
                ){
                    value
                }

                lifestyle: metafield(
                    namespace: "accentuate"
                    key: "lifestyle"
                ){
                    value
                }

                soldout: metafield(
                    namespace: "accentuate"
                    key: "soldout"
                ){
                    value
                }

                preorder: metafield(
                    namespace: "accentuate"
                    key: "preorder"
                ){
                    value
                }

                profile: metafield(
                    namespace: "accentuate"
                    key: "profile"
                ){
                    value
                }

                benefits: metafield(
                    namespace: "accentuate"
                    key: "benefits"
                ){
                    value
                }

                caption: metafield(
                    namespace: "accentuate"
                    key: "caption"
                ){
                    value
                }

                renderingVideo: metafield(
                    namespace: "rendering"
                    key: "video"
                ){
                    value
                }

                renderingScale: metafield(
                    namespace: "rendering"
                    key: "scale"
                ){
                    value
                }

                renderingOffset: metafield(
                    namespace: "rendering"
                    key: "offset"
                ){
                    value
                }

                renderingFallback: metafield(
                    namespace: "rendering"
                    key: "fallback"
                ){
                    value
                }


                primaryNotesName: metafield(
                    namespace: "primary_notes"
                    key: "name"
                ){
                    value
                }

                primaryNotesImage: metafield(
                    namespace: "primary_notes"
                    key: "image"
                ){
                    value
                }

                primaryNotesINCI: metafield(
                    namespace: "primary_notes"
                    key: "inci"
                ){
                    value
                }

                primaryNotesOrigin: metafield(
                    namespace: "primary_notes"
                    key: "origin"
                ){
                    value
                }

                primaryNotesExtraction: metafield(
                    namespace: "primary_notes"
                    key: "extraction"
                ){
                    value
                }

                primaryNotesDescription: metafield(
                    namespace: "primary_notes"
                    key: "description"
                ){
                    value
                }

                keyBenefitsName: metafield(
                    namespace: "key_benefits"
                    key: "name"
                ){
                    value
                }

                keyBenefitsMethod: metafield(
                    namespace: "key_benefits"
                    key: "method"
                ){
                    value
                }

                keyBenefitsImage: metafield(
                    namespace: "key_benefits"
                    key: "image"
                ){
                    value
                }

                keyBenefitsDescription: metafield(
                    namespace: "key_benefits"
                    key: "description"
                ){
                    value
                }

                whatsIncludedName: metafield(
                    namespace: "included"
                    key: "name"
                ){
                    value
                }

                whatsIncludedImage: metafield(
                    namespace: "included"
                    key: "image"
                ){
                    value
                }

                whatsIncludedType: metafield(
                    namespace: "included"
                    key: "type"
                ){
                    value
                }

                whatsIncludedDescription: metafield(
                    namespace: "included"
                    key: "description"
                ){
                    value
                }

                ingredients: metafield(
                    namespace: "list"
                    key: "ingredients"
                ){
                    value
                }

                origin: metafield(
                    namespace: "accentuate"
                    key: "origin"
                ){
                    value
                }

                extraction: metafield(
                    namespace: "accentuate"
                    key: "extraction"
                ){
                    value
                }

                boxHeadline: metafield(
                    namespace: "box"
                    key: "headline"
                ){
                    value
                }

                boxDescription: metafield(
                    namespace: "box"
                    key: "description"
                ){
                    value
                }

                boxMedia: metafield(
                    namespace: "box"
                    key: "media"
                ){
                    value
                }

                boxSafety: metafield(
                    namespace: "box"
                    key: "safety"
                ){
                    value
                }

                storyBackground: metafield(
                    namespace: "story"
                    key: "background"
                ){
                    value
                }

                storyMediaImage: metafield(
                    namespace: "story"
                    key: "media"
                ){
                    value
                }

                storyMediaVideo: metafield(
                    namespace: "story"
                    key: "media_video"
                ){
                    value
                }

                storyTitle: metafield(
                    namespace: "story"
                    key: "title"
                ){
                    value
                }

                storySubheader: metafield(
                    namespace: "story"
                    key: "subheader"
                ){
                    value
                }

                storyContent: metafield(
                    namespace: "story"
                    key: "content"
                ){
                    value
                }

                filtersScents: metafield(
                    namespace: "filters"
                    key: "scents"
                ){
                    value
                }

                filtersApplications: metafield(
                    namespace: "filters"
                    key: "applications"
                ){
                    value
                }

                filtersTreatments: metafield(
                    namespace: "filters"
                    key: "treatments"
                ){
                    value
                }

                filtersBenefits: metafield(
                    namespace: "filters"
                    key: "benefits"
                ){
                    value
                }

                ingredientsBlockImage: metafield(
                    namespace: "ingredients"
                    key: "block_image"
                ){
                    value
                }

                ingredientsBlockTitle: metafield(
                    namespace: "ingredients"
                    key: "title"
                ){
                    value
                }

                ingredientsBlockDescription: metafield(
                    namespace: "ingredients"
                    key: "block_description"
                ){
                    value
                }

                benefitsBlockImage: metafield(
                    namespace: "benefits_block"
                    key: "block_image"
                ){
                    value
                }

                benefitsBlockTitle: metafield(
                    namespace: "benefits_block"
                    key: "title"
                ){
                    value
                }

                benefitsBlockDescription: metafield(
                    namespace: "benefits_block"
                    key: "description"
                ){
                    value
                }

                scenarioHeader: metafield(
                    namespace: "scenario"
                    key: "header"
                ){
                    value
                }

                scenarioImage: metafield(
                    namespace: "scenario"
                    key: "image"
                ){
                    value
                }

                scenarioCategory: metafield(
                    namespace: "scenario"
                    key: "category"
                ){
                    value
                }

                scenarioMedia: metafield(
                    namespace: "scenario"
                    key: "media"
                ){
                    value
                }

                scenarioTitle: metafield(
                    namespace: "scenario"
                    key: "title"
                ){
                    value
                }

                scenarioContent: metafield(
                    namespace: "scenario"
                    key: "content"
                ){
                    value
                }
                
                seo {
                    title
                    description
                }

            }
        `
    },




    /**
    *
    *   format
    *       - Format the Product Response
    *
    *   Params:
    *       obj:        (Object) The Parent Object
    *       product     (Object) The Product Objects
    *       allowFree:  (Boolean) Allow Free Objects)
    *
    **/
    format: (obj,product,allowFree) => {

        //Is the product sold out?
        const soldout = ( product.soldout?.value ? Boolean( product.soldout.value ) : false );

        //
        product = {
            ...product,

            //The Template
            template: ( product.template?.value ? product.template.value : 'default' ),

            //INCI Name
            inci:  ( product.scientificName?.value ? product.scientificName?.value : null ),

            //Made Locally?
            local: ( product.madeLocal?.value ? product.madeLocal.value : null ),

            //Origin
            origin: ( product.origin?.value ? product.origin.value : null ),

            //Sold Out
            soldout: soldout,

            //Profile
            preorder: ( product.preorder?.value ? Boolean( product.soldout.preorder ) : false ),

            //Extraction
            extraction: ( product.extraction?.value ? product.extraction.value : null ),

            //Featured Image
            featuredImage: ( product.featuredImage?.url || null ),

            //Profile
            profile: ( product.profile?.value ? obj.parseJSON( product.profile.value ) : null ),

            //Profile
            benefits: ( product.benefits?.value ? obj.parseJSON( product.benefits.value ) : null ),

            //Primary Notes
            primaryNotes: ((response)=>{

                //Prepare Data
                const chunks = {
                    name:           ( product.primaryNotesName?.value ? obj.parseJSON( product.primaryNotesName.value ) : null ),
                    image:          ( product.primaryNotesImage?.value ? obj.parseJSON( product.primaryNotesImage.value ) : null ),
                    inci:           ( product.primaryNotesINCI?.value ? obj.parseJSON( product.primaryNotesINCI.value ) : null ),
                    origin:         ( product.primaryNotesOrigin?.value ? obj.parseJSON( product.primaryNotesOrigin.value ) : null ),
                    extraction:     ( product.primaryNotesExtraction?.value ? obj.parseJSON( product.primaryNotesExtraction.value ) : null ),
                    description:    ( product.primaryNotesDescription?.value ? obj.parseJSON( product.primaryNotesDescription.value ) : null ),
                };

                //Get Chunks
                ( chunks.name || [] ).forEach( (name,i) => {
                    response.push({
                        name:           chunks.name?.[i],
                        image:          chunks.image?.[i],
                        inci:           chunks.inci?.[i],
                        origin:         chunks.origin?.[i],
                        extraction:     chunks.extraction?.[i],
                        description:    chunks.description?.[i]
                    })
                });

                //Return
                return response;

            })([]),


            //Key Benefits
            keyBenefits: ((response)=>{

                //Prepare Data
                const chunks = {
                    name:           ( product.keyBenefitsName?.value ? obj.parseJSON( product.keyBenefitsName.value ) : null ),
                    method:         ( product.keyBenefitsMethod?.value ? obj.parseJSON( product.keyBenefitsMethod.value ) : null ),
                    image:          ( product.keyBenefitsImage?.value ? obj.parseJSON( product.keyBenefitsImage.value ) : null ),
                    description:    ( product.keyBenefitsDescription?.value ? obj.parseJSON( product.keyBenefitsDescription.value ) : null ),
                };

                //Get Chunks
                ( chunks.name || [] ).forEach( (name,i) => {
                    response.push({
                        name:           chunks.name?.[i],
                        method:         chunks.method?.[i],
                        image:          chunks.image?.[i],
                        description:    chunks.description?.[i]
                    })
                });

                //Return
                return response;

            })([]),

            //What's Included
            whatsIncluded: ((response)=>{

                //Prepare Data
                const chunks = {
                    name:           ( product.whatsIncludedName?.value ? obj.parseJSON( product.whatsIncludedName.value ) : null ),
                    image:          ( product.whatsIncludedImage?.value ? obj.parseJSON( product.whatsIncludedImage.value ) : null ),
                    type:           ( product.whatsIncludedType?.value ? obj.parseJSON( product.whatsIncludedType.value ) : null ),
                    description:    ( product.whatsIncludedDescription?.value ? obj.parseJSON( product.whatsIncludedDescription.value ) : null ),
                };

                //Get Chunks
                ( chunks.name || [] ).forEach( (name,i) => {
                    response.push({
                        name:           chunks.name?.[i],
                        image:          chunks.image?.[i],
                        type:           chunks.type?.[i],
                        description:    chunks.description?.[i]
                    })
                });

                //Return
                return response;

            })([]),

            //Ingredients
            ingredients: ( product.ingredients?.value || null ),

            //Caption
            caption: ( product.caption?.value || null ),

            //Rendering
            rendering: {
                video:      ( product.renderingVideo?.value ? obj.parseJSON( product.renderingVideo.value ) : null ),
                scale:      ( product.renderingScale?.value || 1 ),
                offset:     ( product.renderingOffset?.value || 0 ),
                fallback:   ( product.renderingFallback?.value ? obj.parseJSON( product.renderingFallback.value ) : null )
            },

            //Product Rendering Scale
            scale: ( product.renderingScale?.value || 1 ),

            //Lifestyle
            lifestyle: ( product.lifestyle?.value ? obj.parseJSON( product.lifestyle.value )[0] : null ),

            //Box Photo
            box: {
                headline:   ( product.boxHeadline?.value ? obj.parseJSON( product.boxHeadline.value ) : null ),
                content:    ( product.boxDescription?.value ? obj.parseJSON( product.boxDescription.value ) : null ),
                photo:      ( product.boxMedia?.value ? obj.parseJSON( product.boxMedia.value )[0] : null ),
                safety:     ( product.boxSafety?.value ? obj.parseJSON( product.boxSafety?.value ) : null )
            },

            //Story
            story: {
                background: ( product.storyBackground?.value ? obj.parseJSON( product.storyBackground.value ) : null ),
                title:      ( product.storyTitle?.value ? obj.parseJSON( product.storyTitle.value ) : null ),
                subheader:  ( product.storySubheader?.value ? obj.parseJSON( product.storySubheader.value ) : null ),
                content:    ( product.storyContent?.value ? obj.parseJSON( product.storyContent.value ) : null ),
                media:      {
                    image:      ( product.storyMediaImage?.value ? obj.parseJSON( product.storyMediaImage.value )[0] : null ),
                    video:      ( product.storyMediaVideo?.value ? product.storyMediaVideo.value : null ),
                }
            },

            blocks: {
                benefits: {
                    image:          ( product.benefitsBlockImage?.value ? obj.parseJSON( product.benefitsBlockImage.value )[0] : null ),
                    title:          ( product.benefitsBlockTitle?.value ? obj.parseJSON( product.benefitsBlockTitle.value ) : null ),
                    description:    ( product.benefitsBlockDescription?.value ? obj.parseJSON( product.benefitsBlockDescription.value ) : null )
                },
                ingredients: {
                    image:          ( product.ingredientsBlockImage?.value ? obj.parseJSON( product.ingredientsBlockImage.value )[0] : null ),
                    title:          ( product.ingredientsBlockTitle?.value ? obj.parseJSON( product.ingredientsBlockTitle.value ) : null ),
                    description:    ( product.ingredientsBlockDescription?.value ? obj.parseJSON( product.ingredientsBlockDescription.value ) : null )
                },
                scenarios: {
                    header:     ( product.scenarioHeader?.value ? obj.parseJSON( product.scenarioHeader.value ) : null ),
                    image:      ( product.scenarioImage?.value ? obj.parseJSON( product.scenarioImage.value )?.[0] : null ),
                    list:       ((list)=>{
                        const chunks = {
                            category:      ( product.scenarioCategory?.value ? obj.parseJSON( product.scenarioCategory.value ) : null ),
                            media:         ( product.scenarioMedia?.value ? obj.parseJSON( product.scenarioMedia.value ) : null ),
                            title:         ( product.scenarioTitle?.value ? obj.parseJSON( product.scenarioTitle.value ) : null ),
                            content:       ( product.scenarioContent?.value ? obj.parseJSON( product.scenarioContent.value ) : null ),
                        };
                        ( chunks.category || [] ).forEach( (category,i) => {
                            list.push({
                                category:   category,
                                media:      ( chunks.media?.[i] || null ),
                                title:      ( chunks.title?.[i] || null ),
                                content:    ( chunks.content?.[i] || null )
                            });
                        })
                        return list;
                    })([])
                }
            },

            //Filters
            filter: {
                scents:         ( product.filtersScents?.value?.split('|') || null ),
                applications:   ( product.filtersApplications?.value?.split('|') || null ),
                treatments:     ( product.filtersTreatments?.value?.split('|') || null ),
                benefits:       ( product.filtersBenefits?.value?.split('|') || null )
            },

            //Badges
            badges: ((badges)=>{

                //Parse the JSON
                ['badgeText','badgeBackgroundColor','badgeTextColor'].forEach(
                    type => {
                        if(product[type]) product[type] = obj.parseJSON( product[type].value )
                    }
                );

                //Loop through the badge text
                if( product.badgeText ){
                    for( var i=0; i < product.badgeText.length; i++ ){
                        badges.push({
                            text:   product.badgeText[i],
                            styles: {
                                backgroundColor:    ( product.badgeBackgroundColor?.[i] || null ),
                                color:              ( product.badgeTextColor?.[i] || null )
                            }
                        });
                    }
                }

                return badges;
            })([]),

            //Collections
            collections: ((collections) => {
                product.collections.edges.forEach( collection => {
                    collections.push({
                        ...collection.node
                    })
                });
                return collections;
            })([]),

            //Media
            media: ((media) => {
                product.media.edges.forEach( asset => {
                    media.push({
                        ...asset.node
                    })
                });
                return media;
            })([]),

            //Add the Variants + Free Gifts
            ...((variants,samples) => {
                product.variants.edges.forEach( variant => {

                    //Format Pricing
                    variant.node.price.amount = variant.node.price.amount;

                    //Formate Discount
                    if( variant.node.compareAtPrice?.amount )
                        variant.node.compareAtPrice.amount = variant.node.compareAtPrice.amount;

                    //Store the Options
                    variant.node.options = variant.node.title.split(' / ');

                    //Set the Preorder
                    variant.node.preorder = ( variant.node.preorder?.value ? Boolean( variant.node.preorder.value ) : false );

                    //Set the Sold Out
                    variant.node.soldout = ( variant.node.soldout?.value ? Boolean( variant.node.soldout.value ) : false );

                    //Set the Sold Out
                    variant.node.soldout = ( variant.node.soldout || soldout || !variant.node.availableForSale );

                    //Is Available for Wholesale
                    variant.node.wholesale = ( variant.node.wholesale?.value ? Boolean( variant.node.wholesale.value ) : false );

                    //Overwrite
                    if( variant.node.price.amount > 0 ) variants.push({
                        ...variant.node
                    });

                    if( variant.node.price.amount == 0 ) samples.push({
                        ...variant.node
                    })

                });
                return {
                    variants:   variants,
                    samples:    samples
                }
            })([],[])
        }

        //Get the Base Variant
        product.variant = ((variant)=>{
            product.variants.forEach( item => {
                if( item.availableForSale && ( parseFloat( item.price.amount ) < parseFloat( variant.price.amount ) ) ){
                    variant = item;
                }
            });
            return variant;
        })( product.variants[0] );

        //Update the Soldout to include variants
        //product.soldout = ( soldout || product.variant.soldout );

        //Cleanup
        delete product.filtersScents;
        delete product.filtersApplications;
        delete product.filtersTreatments;
        delete product.filtersBenefits;
        delete product.renderingVideo;
        delete product.renderingScale;
        delete product.renderingOffset;
        delete product.badgeText;
        delete product.badgeBackgroundColor;
        delete product.badgeTextColor;
        delete product.boxHeadline;
        delete product.boxDescription;
        delete product.boxMedia;
        delete product.boxSafety;
        delete product.storyBackground;
        delete product.storyMediaImage;
        delete product.storyMediaVideo;
        delete product.storyTitle;
        delete product.storyContent;
        delete product.primaryNotesName;
        delete product.primaryNotesImage;
        delete product.primaryNotesINCI;
        delete product.primaryNotesOrigin;
        delete product.primaryNotesExtraction;
        delete product.primaryNotesDescription;
        delete product.scenarioCategory;
        delete product.scenarioContent;
        delete product.scenarioHeader;
        delete product.scenarioImage;
        delete product.scenarioMedia;
        delete product.scenarioTitle;
        delete product.keyBenefitsName;
        delete product.keyBenefitsMethod;
        delete product.keyBenefitsImage;
        delete product.keyBenefitsDescription;
        delete product.ingredientsBlockImage;
        delete product.ingredientsBlockTitle;
        delete product.ingredientsBlockDescription;
        delete product.benefitsBlockImage;
        delete product.benefitsBlockTitle;
        delete product.benefitsBlockDescription;

        //Return
        return product;
    }
}

//Export
export default obj;