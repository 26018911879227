/**
*
*   LogoTag
*       - Run on Client Side, check if it's the home page or not
*
**/
'use client';


import Config from '@/config';
import ImageVariant from 'next/image';

//Get the Path
export const getPath = function( src , base=false ){

    //The Folder to use
    let folder = ( !base ? Config.versions.images : '/assets/' );

    //
    return ( src.indexOf('http') > -1 ? src : folder + src );

}


//Build the Element
export default function Image({ src='', base=false, ...props }){

    let attributes = Object.assign({},props);

    //Update the context
    attributes.src = getPath( src , base );

    //Return
    return (
        <ImageVariant {...attributes} />
    );


}