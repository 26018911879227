/**
*
*   Shopify Storefront Connection
*       - The Request Connection used for the Storefront data
*
**/

//The Endpoints
import Config from '@/config';
import Articles from './endpoints/articles';
import Article from './endpoints/article';
import Blog from './endpoints/blog';
import Store from './endpoints/store';
import Page from './endpoints/page';
import Products from './endpoints/products';
import Product from './endpoints/product';
import Search from './endpoints/search';
import Customer from './endpoints/customer';
import Collection from './endpoints/collections';
import Cart from './endpoints/cart';
import parseCurrency from 'parsecurrency';
import { useLocale } from 'next-intl';



//Apply the Locale
var locale = ((locale) => {

    //Server Side Locale
    if( typeof window === 'undefined' )
        return 'en';

    //HTML Language
    if( document.documentElement.lang )
        return locale = document.documentElement.lang;

    //Fallback locale
    return ( window.navigator.userLanguage || window.navigator.language ).split('-')[0];

})(null);


//The headers
var headers = {};


/**
*
*   Shopify Requesat Class
*
*
**/
const Storefront = ( () => {

    //Setup the Object
    const obj = {

        //Shopify Storefront Endpoint
        endpoint: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,

        //Shopify Storefront Access Token
        key: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,



        /**
        *
        *   parseJSON
        *       - Parse the field for JSON
        *       - If it's invalid, return NULL
        *
        *   Params:
        *       item:    (String) The String to Parse
        *
        *
        **/
        parseJSON: (item) => {
            try {
                if( !!(JSON.parse(item) && item) ) return JSON.parse(item);
            } catch(e) {
                return null
            }
        },



        /**
        *
        *   formatPricing
        *       - Force either two decimals or no decimals
        *
        *   Params:
        *       item:    (String) The String to Parse
        *
        *
        **/
        formatPrice: (price) => {
            //No price, just return
            if( !price ) return price;
            //Attempts to parseCurrency for foreign currencies, falls back to default numeric (if there is multiple decimal points due to division)
            return ( parseCurrency( price.toString() )?.value || price ).toLocaleString( locale , {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
            }).replace(/(\.|,)00$/,'');
        },





        /**
        *
        *   Request
        *       - The Shopify Storefront Request function
        *
        *   Params:
        *       query:      (String) The GraphQL query to send
        *       variables:  (Object) The Variables to pass
        *
        *
        **/
        Request:        async function( query , variables = {} ){
            try {

                //Prepare the Query
                query = obj[ query.type ]( query );

                //Send Request to Shopify
                const result = await fetch( obj.endpoint , {
                    method:     'POST',
                    headers:    {
                        'Content-Type': 'application/json',
                        'X-Shopify-Storefront-Access-Token': obj.key,
                        ...headers
                    },
                    body:           { query, variables } && JSON.stringify({ query, variables })
                });

                //Reset the headers
                headers = {};

                //Return Response if Successful
                return {
                    status:     result.status,
                    body:       await result.json()
                };

            } catch (error) {

                //Return Error
                return {
                    status: 500,
                    errors:  [
                        error
                    ]
                }
            }

        },






        /**
        *
        *   prepare the query
        *       - Sets up the query localization
        *
        *   Params:
        *       item:    (String) The String to Parse
        *
        *
        **/
        query: ({ variables, request }) => {
            return `
                query${ ( variables ? '(' + variables + ')' : '' ) } @inContext(country:${ Config.country }, language:${ locale.toUpperCase() }){
                    ${request}
                }
            `
        },






        /**
        *
        *   prepare the query
        *       - Sets up the query localization
        *
        *   Params:
        *       item:    (String) The String to Parse
        *
        *
        **/
        mutation: ({ name, variables, request }) => {
            return `
                mutation ${name}${ ( variables ? '(' + variables + ')' : '' ) } @inContext(country:${ Config.country }, language:${ locale.toUpperCase() }){
                    ${request}
                }
            `
        },




        /**
        *
        *   error
        *       - Error Response
        *
        *   Params:
        *       item:    (String) The String to Parse
        *
        *
        **/
        error: (data) => {
            return {
                result: 0,
                errors:  data
            }
        },






        /**
        *
        *   success
        *       - Success
        *
        *   Params:
        *       item:    (String) The String to Parse
        *
        *
        **/
        success: (data) => {
            return {
                result: 1,
                body:   data
            }
        }

    }


    //Return
    return {

        //Format the Price
        money: (price) => obj.formatPrice( price ),

        //Set the new Locale
        setLocale: (value) => locale = value,

        //Set the headers to use in the next request
        setHeaders: (value) => headers = value,

        //Get a Value
        get: (type) => {
            switch( type ){
                case 'error': return error;
                case 'locale': return locale;
                case 'headers': return headers;
                default: return false;
            }
        },

        //Blog articles
        Articles: {

            /**
            *
            *   latest
            *       - Loads the latest articles
            *
            *   Params:
            *       total:  (Int) the total articles to load
            *
            **/
            latest: async (total) => {

                //Get the Response
                let response = await obj.Request( Articles.latest ,{
                    first: ( total || 5 )
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                //if( response.body.data?.articles?.length > 0 ) return obj.error( response.body.data.customerAddressUpdate.customerUserErrors );

                //Return the Address
                return obj.success( Articles.format( obj , response.body?.data?.articles ) );

            },

        },

        //Blog articles
        Article: {

            /**
            *
            *   get
            *       - Loads the latest articles
            *
            *   Params:
            *       total:  (Int) the total articles to load
            *
            **/
            get: async (id) => {

                //Get the Response
                let response = await obj.Request( Article.get ,{
                    id: id
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                //if( response.body.data?.articles?.length > 0 ) return obj.error( response.body.data.customerAddressUpdate.customerUserErrors );

                //Return the Address
                return obj.success( Article.format( obj , response.body?.data?.article ) );

            },

            /**
            *
            *   comments
            *       - Loads the comments by article id
            *
            *   Params:
            *       id:     (INT) The article ID to load the comments for
            *
            **/
            comments: async (id) => {

                //Get the Response
                let response = await obj.Request( Article.comments ,{
                    id: id
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                //if( response.body.data?.articles?.length > 0 ) return obj.error( response.body.data.customerAddressUpdate.customerUserErrors );

                //Return the Address
                return obj.success( Article.format( obj , response.body?.data?.article ) );

            },

        },

        //Blog articles
        Blog: {

            /**
            *
            *   get
            *       - Loads a Blog by the Handle
            *
            *   Params:
            *       total:  (Int) the total articles to load
            *
            **/
            get: async (handle) => {

                //Get the Response
                let response = await obj.Request( Blog.get , {
                    handle: handle
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                //if( response.body.data?.articles?.length > 0 ) return obj.error( response.body.data.customerAddressUpdate.customerUserErrors );

                //Return the Address
                return obj.success( Blog.format( obj , response.body?.data?.blog ) );

            },

            /**
            *
            *   articles
            *       - Loads all Articles in a blog
            *
            *   Params:
            *       total:  (Int) the total articles to load
            *
            **/
            articles: async (handle) => {

                //Get the Response
                let response = await obj.Request( Blog.articles , {
                    handle: handle
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                //if( response.body.data?.articles?.length > 0 ) return obj.error( response.body.data.customerAddressUpdate.customerUserErrors );

                //Return the Address
                return obj.success( Blog.format( obj , response.body?.data?.blog ) );

            },

            /**
            *
            *   article
            *       - Loads an Article by the Blog Handle and Article Handle
            *
            *   Params:
            *       total:  (Int) the total articles to load
            *
            **/
            article: async (blog, article) => {

                //Get the Response
                let response = await obj.Request( Blog.article , {
                    blog:       blog,
                    article:    article
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                //if( response.body.data?.articles?.length > 0 ) return obj.error( response.body.data.customerAddressUpdate.customerUserErrors );

                //Return the Address
                return obj.success( Blog.format( obj , response.body?.data?.blog ) );

            },

        },


        //Store Requests
        Store: {

            /**
            *
            *   Store.load
            *       - Loads the Store Data
            *
            *   Params:
            *       n/a
            *
            **/
            load: async () => {
                let response = await obj.Request( Store.data , {} );
                return response.status == 200 ? obj.success( response.body.data ) : obj.error( response.errors ) ;
            }

        },

        Page: {


            /**
            *
            *   Page.get
            *       - Load the specific page data
            *
            *   Params:
            *       n/a
            *
            **/
            get: async handle => {
                let response = await obj.Request( Page.load , { handle: handle } );
                return response.status == 200 ? obj.success( response.body.data.pageByHandle ) : obj.error( response.errors ) ;
            }
        },

        //Product Request
        Product: {


            /**
            *
            *   Product.get
            *       - Loads the Product Data
            *
            *   Params:
            *       ids:        (Array) List of Product GIDs
            *
            **/
            load: async handle => {

                //The Response
                let response = await obj.Request( Product.load , { handle: handle } ),
                    product  = response.body?.data?.product;

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response.body.errors ) return obj.error( response.body.errors );
                if( !( response.body?.data?.product ) ) return obj.success( false );


                //Return the Response
                return obj.success( Product.format( obj , product ) );

            }


        },

        //Products Request
        Products: {


            /**
            *
            *   Products.all
            *       - Loads all of the product data
            *
            *   Params:
            *       ids:        (Array) List of Product GIDs
            *
            **/
            all: async () => {

                //The Response
                let response = await obj.Request( Products.all );

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response.body.errors ) return obj.error( response.body.errors );
                if( !response.body?.data?.products?.edges || response.body.data.products.edges.length == 0 ) return obj.success( false );

                //Return the Response
                return obj.success(
                    Products.format( obj , response.body.data.products.edges.map( row => row.node ) )
                );

            },


            /**
            *
            *   Products.latest
            *       - Loads the last 10 created products
            *
            *   Params:
            *       ids:        (Array) List of Product GIDs
            *
            **/
            latest: async () => {

                //The Response
                let response = await obj.Request( Products.latest );

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response.body.errors ) return obj.error( response.body.errors );
                if( !response.body?.data?.products?.edges || response.body.data.products.edges.length == 0 ) return obj.success( false );

                //Return the Response
                return obj.success(
                    Products.format( obj , response.body.data.products.edges.map( row => row.node ) )
                );

            },


            /**
            *
            *   Products.bestsellers
            *       - Loads the top 10 best sellers
            *
            *   Params:
            *       ids:        (Array) List of Product GIDs
            *
            **/
            bestsellers: async () => {

                //The Response
                let response = await obj.Request( Products.bestsellers );

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response.body.errors ) return obj.error( response.body.errors );
                if( !response.body?.data?.products?.edges || response.body.data.products.edges.length == 0 ) return obj.success( false );

                //Return the Response
                return obj.success(
                    Products.format( obj , response.body.data.products.edges.map( row => row.node ) )
                );

            },


            /**
            *
            *   Products.get
            *       - Loads the Product Data
            *
            *   Params:
            *       ids:        (Array) List of Product GIDs
            *
            **/
            get: async ids => {

                //The Response
                let response = await obj.Request( Products.get , { ids: ids } );

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response.body.errors ) return obj.error( response.body.errors );
                if( !response.body?.data?.nodes || response.body.data.nodes.length == 0 ) return obj.success( false );

                //Return the Response
                return obj.success(
                    Products.format( obj , response.body.data.nodes )
                );

            },




            /**
            *
            *   Products.recommendations
            *       - Loads Product Recommendations by ids
            *
            *   Params:
            *       ids:        (Array) List of Product GIDs
            *       intent:     (String) RELATED or COMPLIMENTARY
            *
            **/
            recommendations: async ( id , intent = 'RELATED' ) => {

                //The Response
                let response = await obj.Request( Products.recommendations, {
                    id:         id,
                    intent:     intent
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response.body.errors ) return obj.error( response.body.errors );

                return obj.success(
                    Products.format( obj , response.body.data.productRecommendations )
                );

            }

        },


        //Collection Requests
        Collection: {

            /**
            *
            *   Collection.load
            *       - Loads the Collection data
            *
            *   Params:
            *       handle:     (String) The Handle to load
            *
            **/
            load: async handle => {

                //Get the Response
                let response = await obj.Request( Collection.load , { handle: handle } );

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( !response.body?.data?.collection ) return obj.success( false );


                //Return
                return obj.success(
                    Collection.format( obj , response.body.data.collection )
                );

            },


            /**
            *
            *   Collection.title
            *       - Loads a Collection title
            *
            *   Params:
            *       handle:     (String) The Handle to load
            *
            **/
            title: async handle => {

                //Get the Response
                let response = await obj.Request( Collection.title , { handle: handle } );

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( !response.body?.data?.collection ) return obj.success( false );

                //Return the Response
                return obj.success( response.body.data.collection );

            }

        },

        //Customer Data
        Customer:  {


            /**
            *
            *   Search.query
            *       - Searches Shopify based on the supplied query
            *
            *   Params:
            *       query:     (String) The Search Query
            *
            **/
            login: async ( email , password ) => {

                //Get the Response
                let response = await obj.Request( Customer.login , {
                    input: {
                        email:      email,
                        password:   password
                    }
                } );

                //Failed Response
                if( response.status != 200 ) 
                    return obj.error( response );

                //Invalid Response
                if( !response.body?.data?.customerAccessTokenCreate )
                    return obj.error({
                        error:  'Unknown Error',
                        code:   'bad-request'
                    });

                //Get The Response
                response = response.body?.data?.customerAccessTokenCreate;

                //Valid Response, Errors Returned
                if( response?.customerUserErrors.length > 0 ) 
                    return obj.error( response?.customerUserErrors );

                //Valid Response, Success
                return obj.success( response.customerAccessToken );

            },


            /**
            *
            *   Customer.get
            *       - Loads customer details based on their token
            *
            *   Params:
            *       token:     (String) The Customer Token
            *
            **/
            get: async token => {

                //Get the Response
                let response = await obj.Request( Customer.get , { token: token });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( !response.body?.data?.customer ) return obj.success( false );

                //Return the Customer
                return obj.success( response.body.data.customer );

            },


            /**
            *
            *   Customer.update
            *       - Updates a customer
            *
            *   Params:
            *       query:     (String) The Search Query
            *
            **/
            update: async ( token , input ) => {

                //Get the Response
                let response = await obj.Request( Customer.update , { token: token , input: input });

                //Failed Response
                if( response.status != 200 ) return obj.error( response.errors );
                if( response.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                if( response.body.data.customerUpdate?.customerUserErrors?.length > 0 ) return obj.error( response.body.data.customerUpdate.customerUserErrors );

                //Return the Address
                return obj.success( response.body?.data?.customerUpdate?.customer );

            },



            addresses: {

                /**
                *
                *   Customer.addresses.get
                *       - Loads customer addresses
                *
                *   Params:
                *       token:     (String) The Customer Token
                *
                **/
                list: async token => {

                    //Get the Response
                    let response = await obj.Request( Customer.addresses.list , { token: token });

                    //Return Fail
                    if( response.status != 200 ) return obj.error( response.errors );

                    //Return the Customer
                    return obj.success( Customer.format( obj , response.body.data.customer ) );

                },

                /**
                *
                *   Customer.addresses.update
                *       - Update an existing address
                *
                *   Params:
                *       token:     (String) The Customer Token
                *
                **/
                create: async ( token , address ) => {

                    //Get the Response
                    let response = await obj.Request( Customer.addresses.create , { token: token , address: address });

                    //Return Fail
                    if( response.status != 200 ) return obj.error( response.errors );
                    if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                    if( response.body?.data?.customerAddressCreate?.customerUserErrors?.length > 0 ) return obj.error( response.body.data.customerAddressCreate.customerUserErrors );

                    //Return the Address
                    return obj.success( response.body?.data?.customerAddressCreate.customerAddress );

                },

                /**
                *
                *   Customer.addresses.update
                *       - Update an existing address
                *
                *   Params:
                *       token:     (String) The Customer Token
                *
                **/
                update: async ( token , id , input ) => {

                    //Get the Response
                    let response = await obj.Request( Customer.addresses.update , { token: token , id: id , address: input });

                    //Return Fail
                    if( response.status != 200 ) return obj.error( response.errors );
                    if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                    if( response.body.data?.customerAddressUpdate?.customerUserErrors?.length > 0 ) return obj.error( response.body.data.customerAddressUpdate.customerUserErrors );

                    //Return the Address
                    return obj.success( response.body?.data?.address );

                },



                /**
                *
                *   Customer.addresses.delete
                *       - Delete an existing address
                *
                *   Params:
                *       token:     (String) The Customer Token
                *
                **/
                delete: async ( token , id ) => {

                    //Get the Response
                    let response = await obj.Request( Customer.addresses.delete , { token: token , id: id });

                    //Return Fail
                    if( response.status != 200 ) return obj.error( response.errors );
                    if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                    if( response?.body?.data?.customerAddressDelete?.customerUserErrors?.length > 0 ) return obj.error( response.body.data.customerAddressDelete.customerUserErrors );

                    //Get The Response
                    response = response.body?.data?.customerAddressDelete;

                    //Return the Address
                    return obj.success( response );

                },

                /**
                *
                *   Customer.addresses.update
                *       - Update an existing address
                *
                *   Params:
                *       token:     (String) The Customer Token
                *
                **/
                setDefault: async ( token , addressId ) => {

                    //Get the Response
                    let response = await obj.Request( Customer.addresses.setDefault , { token: token , addressId: addressId });

                    //Return Fail
                    if( response.status != 200 ) return obj.error( response.errors );
                    if( response?.body?.errors?.length > 0 ) return obj.error( response.body.errors );
                    if( response?.body?.data?.customerDefaultAddressUpdate?.customerUserErrors?.length > 0 ) return obj.error( response.body.data.customerDefaultAddressUpdate.customerUserErrors );

                    //Return the Address
                    return obj.success( response.body?.data?.customerDefaultAddressUpdate.customer );

                },


            },


            /**
            *
            *   Customer.create
            *       - Creates a Customer
            *
            *   Params:
            *       data:     (Object) The Customer data
            *
            **/
            create: async customer => {

                //Get the Response
                let response = await obj.Request( Customer.create , { input: customer });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response.body?.errors?.length >= 1 ) return obj.error( response.body.errors );
                if( response.body?.data?.customerCreate?.customerUserErrors?.length >= 1 ) return obj.error( response.body.data.customerCreate.customerUserErrors );
                //if( !response.body?.data?.customer ) return false;

                //Return any errors
                return obj.success( response.body?.data?.customerCreate );

            },


            /**
            *
            *   Customer.create
            *       - Creates a Customer
            *
            *   Params:
            *       data:     (Object) The Customer data
            *
            **/
            activate: async (id, token, password) => {

                //Get the Response
                let response = await obj.Request( Customer.activate , {
                    id:     'gid://shopify/Customer/' + id,
                    input:  {
                        activationToken:    token,
                        password:           password
                    }
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( response.body?.errors?.length >= 1 ) return obj.error( response.body.errors );
                if( !response.body?.data?.customerActivate?.customerUserErrors ) return false;

                //Return the Customer
                return obj.success( response.body.data.customerActivate.customerUserErrors );

            },



            /**
            *
            *   passowrd
            *       - Password Queries
            *
            *
            **/
            password: {





                /**
                *
                *   Customer.password.recover
                *       - Initiate the Customer Password recovery
                *
                *   Params:
                *       token:     (String) The Customer Token
                *
                **/
                recover: async (email) => {

                    //Get the Response
                    let response = await obj.Request( Customer.recover , { email: email });

                    //Return Fail
                    if( response.status != 200 ) return obj.errors( response.errors );
                    if( response.body?.errors?.length >= 1 ) return obj.errors( response.body.errors );
                    if( !response.body?.data?.customerRecover?.customerUserErrors ) return false;

                    //Return the Customer
                    return obj.success( response.body.data.customerRecover.customerUserErrors );

                },





                /**
                *
                *   Customer.password.recover
                *       - Initiate the Customer Password recovery
                *
                *   Params:
                *       id:         (String) The Customer ID
                *       token:      (String) The Customer Reset Token
                *       password:   (String) The new password
                *
                **/
                reset: async (id, token, password) => {


                    //Get the Response
                    let response = await obj.Request( Customer.reset , {
                        id:     'gid://shopify/Customer/' + id,
                        input:  {
                            resetToken:     token,
                            password:       password
                        }
                    });

                    //Return Fail
                    if( response.status != 200 ) return obj.error( response.errors );
                    if( response.body?.errors?.length >= 1 ) return obj.error( response.body.errors );
                    if( !response.body?.data?.customerReset?.customerUserErrors ) return obj.error( false );

                    //Return the Customer
                    return obj.success( response.body.data.customerReset.customerUserErrors );

                }



            },



            /**
            *
            *   passowrd
            *       - Password Queries
            *
            *
            **/
            order: {





                /**
                *
                *   Customer.order.history
                *       - Get a customers Order History
                *       - ** DOES NOT WORK WITH SHOPIFY STOREFRONT **
                *
                *   Params:
                *       data:     (String) The Customer data
                *
                **/
                get: async (token, orderid) => {

                    //Get the Response
                    let response = await obj.Request( Customer.order.get , {
                        token: token,
                        query: 'name:#' + orderid
                    });

                    //Return Fail
                    if( response.status != 200 ) return obj.error( response.errors );
                    if( response.body?.errors?.length >= 1 ) return obj.error( response.body.errors );

                    //Return the Customer
                    return obj.success( 
                        Customer.format( obj , response.body.data.customer )
                    );


                },





                /**
                *
                *   Customer.order.history
                *       - Get a customers Order History
                *
                *   Params:
                *       data:     (String) The Customer data
                *
                **/
                history: async (token) => {

                    //Get the Response
                    let response = await obj.Request( Customer.order.history , {
                        token: token
                    });

                    //Return Fail
                    if( response.status != 200 ) return obj.error( response.errors );
                    if( response.body?.errors?.length >= 1 ) return obj.error( response.body.errors );

                    //Return the Customer
                    return obj.success(
                        Customer.format( obj , response.body.data.customer )
                    );


                }


            }




        },

        //Search
        Search:  {


            /**
            *
            *   Search.query
            *       - Searches Shopify based on the supplied query
            *
            *   Params:
            *       query:     (String) The Search Query
            *
            **/
            query: async query => {

                //Get the Response
                let response = await obj.Request( Search.query , {
                    query:      query,
                    criteria:   ['BODY', 'PRODUCT_TYPE', 'TAG', 'TITLE', 'VARIANTS_SKU', 'VARIANTS_BARCODE', 'VARIANTS_TITLE']
                } );

                //Return Fail
                if( response.status != 200 ) return obj.error( response.errors );
                if( !response.body?.data?.predictiveSearch?.products ) return obj.success( false );

                response = response.body.data.predictiveSearch;

                //Format the Products
                response.products = Products.format( obj, response.products );

                //Return the Query
                return obj.success( response );

            }


        },




        //The Cart Functions
        Cart: {

            /**
            *
            *   Cart.get
            *       - Loads the Cart Data based on the cart ID
            *
            *   Params:
            *       ID:         (String) The Cart ID
            *
            **/
            get: async id => {

                //Get the Response
                let response = await obj.Request( Cart.get , { id: id } );

                //Return Fail
                if( response.status != 200 ) return obj.error( response.body.errors );
                if( !response.body?.data?.cart ) return obj.success( false );

                //Return
                return obj.success(
                    Cart.format( obj , response.body?.data?.cart )
                );

            },

            /**
            *
            *   Cart.create
            *       - Creates a new cart
            *
            *   Params:
            *       handle:     (String) The Handle to load
            *
            **/
            create: async lines => {

                //Get the Response
                let response = await obj.Request( Cart.create , {
                    cart: {
                        buyerIdentity: {
                            countryCode: Config.country
                        },
                        lines: lines
                    }
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.body.errors );

                //Return
                return obj.success(
                    Cart.format( obj, response.body?.data?.cartCreate?.cart )
                );

            },

            /**
            *
            *   Cart.update
            *       - Creates a new cart
            *
            *   Params:
            *       id:         (string) The Cart ID
            *       lines:      (array) The cart line item data
            *
            **/
            add: async ( id , lines ) => {

                //Get the Response
                let response = await obj.Request( Cart.add , {
                    id: id,
                    lines: lines
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.body.errors );
                
                //Return
                return obj.success( 
                    Cart.format( obj, response.body?.data?.cartLinesAdd?.cart )
                );

            },

            /**
            *
            *   Cart.update
            *       - Updates Line Items in the cart
            *
            *   Params:
            *       id:         (string) The Cart ID
            *       lines:      (array) The cart line item data
            *
            **/
            update: async ( id , lines ) => {

                //Get the Response
                let response = await obj.Request( Cart.update , {
                    id: id,
                    lines: lines
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.body.errors );

                //Return
                return obj.success(
                    Cart.format( obj, response.body?.data?.cartLinesUpdate?.cart )
                );

            },

            /**
            *
            *   Cart.update
            *       - Creates a new cart
            *
            *   Params:
            *       id:         (string) The Cart ID
            *       lines:      (array) The cart line item data
            *
            **/
            remove: async ( cartId , lineId ) => {

                //Get the Response
                let response = await obj.Request( Cart.remove , {
                    id: cartId,
                    lines: [ lineId ]
                });

                //Return Fail
                if( response.status != 200 ) return obj.error( response.body.errors );

                //Return
                return obj.success(
                    Cart.format( obj, response.body?.data?.cartLinesRemove?.cart )
                );

            }

        }
    }

} )();


//Output
export default Storefront;