/**
*
*   Search
*       - Loads the Store Data
*
**/
const obj = {

    //Load the Product Data
    query: {
        type: 'query',
        variables: '$query: String!, $criteria: [SearchableField!]',
        request: `
            predictiveSearch(query: $query, searchableFields: $criteria){
                products {
                    id
                    title
                    description
                    handle
                    tags
                    productType
                    availableForSale

                    featuredImage {
                        url(transform:{ maxWidth: 600, maxHeight:600 })
                    }

                    media(first: 10) {
                        edges {
                            node {
                                id
                                mediaContentType
                                presentation {
                                    asJson(format: IMAGE)
                                }
                                previewImage {
                                    altText
                                    height
                                    url(transform:{ maxWidth: 1200, maxHeight:1200 })
                                    width
                                }
                                ... on Video {
                                    sources {
                                        format
                                        height
                                        mimeType
                                        url
                                        width
                                    }
                                }
                                ... on ExternalVideo {
                                    id
                                    host
                                    originUrl
                                }
                                ... on MediaImage {
                                    image {
                                        url(transform:{ maxWidth: 1200, maxHeight:1200 })
                                    }
                                }
                            }
                        }
                    }

                    options {
                        name
                        values
                    }

                    collections(first: 10) {
                        edges {
                            node {
                                id
                                handle
                                title
                            }
                        }
                    }

                    variants(first: 5) {
                        edges {
                            node {
                                id
                                title
                                availableForSale

                                price {
                                    amount
                                    currencyCode
                                }

                                compareAtPrice {
                                    amount
                                    currencyCode
                                }

                                selectedOptions {
                                    name
                                    value
                                }

                                image {
                                    url(transform:{ maxWidth: 600, maxHeight:600 })
                                }

                                soldout: metafield(
                                    namespace: "accentuate"
                                    key: "soldout"
                                ){
                                    value
                                }

                                preorder: metafield(
                                    namespace: "accentuate"
                                    key: "preorder"
                                ){
                                    value
                                }

                            }
                        }
                    }

                    scientificName: metafield(
                        namespace: "scientific"
                        key: "name"
                    ){
                        value
                    }

                    madeLocal: metafield(
                        namespace: "accentuate"
                        key: "local"
                    ){
                        value
                    }

                    lifestyle: metafield(
                        namespace: "accentuate"
                        key: "lifestyle"
                    ){
                        value
                    }

                    soldout: metafield(
                        namespace: "accentuate"
                        key: "soldout"
                    ){
                        value
                    }

                    preorder: metafield(
                        namespace: "accentuate"
                        key: "preorder"
                    ){
                        value
                    }

                    profile: metafield(
                        namespace: "accentuate"
                        key: "profile"
                    ){
                        value
                    }

                    benefits: metafield(
                        namespace: "accentuate"
                        key: "benefits"
                    ){
                        value
                    }

                    caption: metafield(
                        namespace: "accentuate"
                        key: "caption"
                    ){
                        value
                    }

                    badgeText: metafield(
                        namespace: "badge"
                        key: "text"
                    ){
                        value
                    }

                    badgeBackgroundColor: metafield(
                        namespace: "badge"
                        key: "background-color"
                    ){
                        value
                    }

                    badgeTextColor: metafield(
                        namespace: "badge"
                        key: "text-color"
                    ){
                        value
                    }

                    origin: metafield(
                        namespace: "accentuate"
                        key: "origin"
                    ){
                        value
                    }


                    extraction: metafield(
                        namespace: "accentuate"
                        key: "extraction"
                    ){
                        value
                    }

                    boxHeadline: metafield(
                        namespace: "box"
                        key: "headline"
                    ){
                        value
                    }

                    boxDescription: metafield(
                        namespace: "box"
                        key: "media"
                    ){
                        value
                    }

                    boxMedia: metafield(
                        namespace: "box"
                        key: "media"
                    ){
                        value
                    }

                    boxSafety: metafield(
                        namespace: "box"
                        key: "safety"
                    ){
                        value
                    }

                    storyBackground: metafield(
                        namespace: "story"
                        key: "background"
                    ){
                        value
                    }

                    storyMediaImage: metafield(
                        namespace: "story"
                        key: "media"
                    ){
                        value
                    }

                    storyMediaVideo: metafield(
                        namespace: "story"
                        key: "media_video"
                    ){
                        value
                    }

                    storyTitle: metafield(
                        namespace: "story"
                        key: "title"
                    ){
                        value
                    }

                    storyContent: metafield(
                        namespace: "story"
                        key: "content"
                    ){
                        value
                    }

                    filtersScents: metafield(
                        namespace: "filters"
                        key: "scents"
                    ){
                        value
                    }

                    filtersApplications: metafield(
                        namespace: "filters"
                        key: "applications"
                    ){
                        value
                    }

                    filtersTreatments: metafield(
                        namespace: "filters"
                        key: "treatments"
                    ){
                        value
                    }

                    filtersBenefits: metafield(
                        namespace: "filters"
                        key: "benefits"
                    ){
                        value
                    }

                }
            }
        `
    }
}

//Export
export default obj;

