
import { JWT } from 'next-auth/jwt';
import Link from 'next/link';
import Credentials from 'next-auth/providers/credentials';
import Storefront from '@/libraries/shopify/class';

/**
*
*   Config Data
*       - The Config Data
*
**/
const Config = {

    //The Shop ID
    shopId: 12784664635,

    //The Domain
    domain: ( process.env.NEXT_PUBLIC_DOMAIN || process.env.VERCEL_URL ),

    //Country
    country: process.env.NEXT_PUBLIC_COUNTRY,

    //The Store Currency
    currency: ( process.env.NEXT_PUBLIC_COUNTRY == 'US' ? 'USD' : 'CAD' ),

    //The Admin emails
    emails: {
        primary: 'info@fernandpetal.com'
    },

    //Social Accounts
    social: {
        facebook:   'fernandpetal.ca',
        instagram:  'fernandpetal.ca'
    },

    //System Versions
    versions: {
        images: '/assets/v/1/'
    },

    //Samples
    samples: [{
        id: 'gid://shopify/Product/6810007044131',
        requirement: 80
    }],

    //The Free Shipping
    shipping: process.env.NEXT_PUBLIC_FREE_SHIPPING,

    //Points per dollar
    points: 20,

    //The available rewards
    rewards: {
        'birthday-gift': {
            value:      5,
            repeatable: false
        },
        'welcome-points': {
            value:      50,
            repeatable: false
        },
        'facebook-like': {
            value:      15,
            repeatable: false
        },
        'follow-instagram': {
            value:      15,
            repeatable: false
        },
        'join-newsletter': {
            value:      25,
            repeatable: false
        },
        'facebook-share': {
            value:      20,
            repeatable: false
        },
        'twitter-share': {
            value:      20,
            repeatable: false
        },
        'reviews': {
            value:      10,
            repeatable: true
        },
        'testimonial': {
            value:      100,
            repeatable: false
        },
        'place-an-order': {
            value:      1,
            repeatable: true
        }
    },

    //Wholesale Settings
    wholesale: {

       //Wholesale Tags
        tags: [ 'Wholesale' , 'Maker' ],

        //Wholesale threshold for free shipping
        shipping: process.env.NEXT_PUBLIC_WHOLESALE_SHIPPING,

        //Shipping Flat Rate
        rate: process.env.NEXT_PUBLIC_WHOLESALE_FLAT_RATE

    },


    //Localization Config
    i18n: {

        //The Default Timezone
        timeZone: 'America/Vancouver',

        //The HTML Translations
        html: {
            profile:    (chunks) => (<Link href="/account">{ chunks }</Link>),
            login:      (chunks) => (<Link href="/account/login">{ chunks }</Link>),
            register:   (chunks) => (<Link href="/account/register">{ chunks }</Link>),
            contact:    (chunks) => (<a href="mailto:info@fernandpetal.com">{ chunks }</a>),
            strong:     (chunks) => (<strong>{ chunks }</strong>),
            em:         (chunks) => (<em>{ chunks }</em>),
            code:       (chunks) => (<code>{ chunks }</code>),
            small:      (chunks) => (<small>{ chunks }</small>),
            br:         ()       => (<br />)
        },

        // A list of all locales that are supported
        locales: [
            'en',
            'fr'
        ],

        //Default Locale
        defaultLocale: 'en',

        //Domains
        domains: [{

            //Testing
            domain:         'localhost:3000',
            defaultLocale:  'en',
            locales:        ['en','fr']

        },{

            //Testing
            domain:         '127.0.0.1:3000',
            defaultLocale:  'en',
            locales:        ['en']

        },{

            //Staging
            domain:         'fern-petal.vercel.app',
            defaultLocale:  'en',
            locales:        ['en','fr']

        },{

            //USA Website
            domain:         'fernandpetal.com',
            defaultLocale:  'en',
            locales:        ['en']

        },{

            //CA Website
            domain:         'fernandpetal.ca',
            defaultLocale:  'en',
            locales:        ['en','fr'],

        }],

        //Don't use primary locale folder
        localePrefix: 'as-needed'
    },


    //Next-Auth Config
    Auth: {
         pages: {
              signIn:        '/account/login',
              //signOut:       '/account/logout',
              error:         '/account/error', // Error code passed in query string as ?error=
              verifyRequest: '/account/verify', // (used for check email message)
              newUser:       '/account/register' // New users will be directed here on first sign in (leave the property out if not of interest)
         },
         providers: [
              Credentials({
                   name: 'Credentials',
                   credentials: {
                        email: {
                             type: 'text'
                        },
                        password: {
                             type: 'password'
                        }
                   },
                   async authorize(credentials) {

                        //Get an Access Token
                        let login = await Storefront.Customer.login(
                             credentials.email,
                             credentials.password
                        );

                        //Throw an Error
                        if( !login.result )
                             throw new Error( JSON.stringify(login) );

                        //Return the Customer
                        return login.body;
                   }
              })
         ],
         callbacks: {
            jwt: async ({ token, user }) => {
                user && (token.accessToken  = user.accessToken);
                user && (token.expiresAt    = user.expiresAt);
                return token;
            },
            session: async ({ session, token }) => {
                session.accessToken = token.accessToken;
                session.expiresAt   = token.expiresAt;
                return session;
            }
        }
    }

}


export default Config;