/**
*
*   Article
*       - GraphQL for Shopify Articles
*
**/
const obj = {



    //Load all the articles
    latest: {
        type: 'query',
        variables: '$id: ID!',
        request: `
            article(id:$id){
                authorV2 {
                    bio
                    email
                    firstName
                    lastName
                    name
                }
                content
                contentHtml
                excerpt
                excerptHtml
                handle
                id
                blog {
                    id
                    handle
                    title
                }
                image {
                    id
                    altText
                    height
                    width
                    url
                }
                video: metafield(
                    namespace: "feature"
                    key: "video"
                ){
                    value
                }
                onlineStoreUrl
                publishedAt
                tags
                title
            }
        `
    },


    //Load the Store Data
    comments: {
        type: 'query',
        variables: '$id: ID!',
        request: `
            article(id:$id){
                comments(first:250){
                    edges {
                        node {
                            author {
                                name
                            }
                            content
                            contentHtml
                            id
                        }
                    }
                }
            }
        `
    },




    /**
    *
    *   format
    *       - Format the Collection List
    *
    *   Params:
    *       product     (Object) The Product Objects
    *
    **/
    format: (obj,response) => {

        //Format the Comments
        if( response.comments )
            response.comments = response.comments.edges.map( item => item.node );

        //Return Formatted Results
        return response;
    }
}

//Export
export default obj;