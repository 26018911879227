/**
*
*   Shopify Store
*       - Loads the Store Data
*
**/
export default (function(obj){

    //The Default response Object
    const response = `
        id
        createdAt
        updatedAt
        checkoutUrl
        lines(first: 50) {
            edges {
                node {
                    id
                    quantity
                    attributes {
                        key
                        value
                    }
                    merchandise {
                        ... on ProductVariant {
                            id
                            title

                            price {
                                amount
                                currencyCode
                            }

                            compareAtPrice {
                                amount
                                currencyCode
                            }

                            image {
                                url(transform:{ maxWidth: 600, maxHeight:600 })
                            }

                            soldout: metafield(
                                namespace: "accentuate"
                                key: "soldout"
                            ){
                                value
                            }

                            preorder: metafield(
                                namespace: "accentuate"
                                key: "preorder"
                            ){
                                value
                            }

                            product {
                                id
                                title
                                handle
                                tags
                                productType
                                availableForSale

                                featuredImage {
                                    url(transform:{ maxWidth: 600, maxHeight:600 })
                                }

                                collections(first: 10) {
                                    edges {
                                        node {
                                            id
                                        }
                                    }
                                }

                                lifestyle: metafield(
                                    namespace: "accentuate"
                                    key: "lifestyle"
                                ){
                                    value
                                }

                            }

                        }
                    }
                    attributes {
                        key
                        value
                    }
                }
            }
        }
        attributes {
            key
            value
        }
        cost {
            totalAmount {
                amount
                currencyCode
            }
            subtotalAmount {
                amount
                currencyCode
            }
            totalTaxAmount {
                amount
                currencyCode
            }
            totalDutyAmount {
                amount
                currencyCode
            }
        }
        buyerIdentity {
            email
            phone
            customer {
                id
            }
            countryCode
            deliveryAddressPreferences {
                ... on MailingAddress {
                    address1
                    address2
                    city
                    provinceCode
                    countryCodeV2
                    zip
                }
            }
        }
    `;


    //Load the Cart data by Cart ID
    obj.get = {
        type: 'query',
        variables: '$id: ID!',
        request: `
            cart(id: $id){
                ${response}
            }
        `
    };



    //Create a new Cart
    obj.create = {
        type: 'mutation',
        name: 'createCart',
        variables: '$cart: CartInput',
        request: `
            cartCreate(input: $cart) {
                cart {
                    ${response}
                }
            }
        `
    };

    //Add to Cart
    obj.add = {
        type: 'mutation',
        name: 'addItem',
        variables: '$lines: [CartLineInput!]!, $id: ID!',
        request: `
            cartLinesAdd(lines:$lines, cartId:$id){
                cart {
                    ${response}
                }
            }
        `
    };

    //Update the Cart
    obj.update = {
        type: 'mutation',
        name: 'updateCart',
        variables: '$lines:[CartLineUpdateInput!]!, $id: ID!',
        request: `
            cartLinesUpdate(lines:$lines, cartId:$id){
                cart {
                    ${response}
                }
            }
        `
    };

    //Add to Cart
    obj.remove = {
        type: 'mutation',
        name: 'removeItem',
        variables: '$lines: [ID!]!, $id: ID!',
        request: `
            cartLinesRemove(lineIds:$lines, cartId:$id){
                cart {
                    ${response}
                }
            }
        `
    };


    /**
    *
    *   format
    *       - Format the Collection List
    *
    *   Params:
    *       product     (Object) The Product Objects
    *
    **/
    obj.format = (obj, data) => {

        //Format the Lines
        data.lines = data?.lines?.edges.map( line => line.node );

        //Get the Total
        [ 'subtotalAmount' , 'totalAmount' , 'totalTaxAmount' ].forEach( type => {
            if( data.cost[type]?.amount ) data.cost[ type ].amount = obj.formatPrice( data.cost[ type ].amount );
        });


        //Format the lines
        data.lines = data.lines.map( line => {
            let data = line.merchandise;
            return {
                id:         line.id,
                quantity:   line.quantity,
                attributes: line.attributes,
                product:    {
                    ...data.product,
                    ...{

                        //Featured Image
                        featuredImage: ( data.product.featuredImage?.url || { url: null } ),

                        //Lifestyle
                        lifestyle: ( data.product.lifestyle?.value ? obj.parseJSON( data.product.lifestyle.value )[0] : null ),

                        //Collections
                        collections: ((collections) => {
                            data.product.collections.edges.forEach( collection => {
                                collections.push({
                                    ...collection.node
                                })
                            });
                            return collections;
                        })([]),

                        //The Variant Selected
                        variant: {

                            //Variant ID
                            id: data.id,

                            //Variant Handle
                            handle: data.handle,

                            //Variant Title
                            title: data.title,

                            //Featured Image
                            image: ( data.image?.url || { url: null } ),

                            //Preorder
                            preorder: data.preorder,

                            //Set the Variant Price
                            price: {
                                ...data.price,
                                amount: obj.formatPrice( data.price.amount )
                            },

                            //Compare At Price
                            compareAtPrice: {
                                ...data.compareAtPrice,
                                amount: ( data.compareAtPrice?.amount ? obj.formatPrice( data.compareAtPrice?.amount ) : 0 )
                            }

                        }

                    }
                }
            }
        })

        //
        return data;

    }


    //Return
    return obj;



})({})